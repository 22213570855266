import React, { useEffect } from 'react';
import { Button } from 'antd';
import { Row, Col } from 'antd';
import { Typography } from 'antd';
import HeroImg from '../images/Hero.svg';
import '../styles/prelogin.scss';
import { useSelector } from 'react-redux';
import { navigate } from 'gatsby';

const { Title } = Typography;

const IndexPage = () => {
  const { isAuthenticated } = useSelector(state => state);

  useEffect(() => {
    if (isAuthenticated) {
      navigate('/home');
    }
  }, [isAuthenticated]);

  const style = { padding: '20px 0' };

  return (
    <div className="container-prelogin">
      <Row style={style} justify="center">
        <Col className="centered" span={24}>
          <img src={HeroImg} alt="Hero.svg" />
        </Col>
      </Row>
      <Row style={style} justify="center">
        <Col className="centered" span={12}>
          <Title className="subtitle" level={4}>
            ¿Ya eres miembro?
          </Title>
        </Col>
      </Row>
      <Row justify="center">
        <Col className="edges-boxes" span={12}>
          <Button href="/register" type="primary" block>
            Quiero asegurarme!
          </Button>
        </Col>
      </Row>
      <Row style={style} justify="center">
        <Col className="edges-boxes" span={12}>
          <Button href="/login" block>
            Iniciar sesión
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default IndexPage;
